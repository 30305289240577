import React, {Component} from 'react';

import Header  from '../components/Header';
import Start from '../components/Start';

export default class StartScreen extends Component {

  componentDidMount() {
    setTimeout(() => {
      document.querySelector('.startGuide').classList.add('_show');
    }, 200)
  }

  render(){
    return(
      <div className="startScreen">
        <Header />
        <Start />
      </div>
    )
  }
}
