import { TRAININGS } from '../actions/trainings';

export const InitialState = {
  videos: [],
  program: null,
  isExpired: null,
};

// REDUCERS
export default function (state = InitialState, action) {
  switch (action.type) {
    case TRAININGS.GET_LIST:
      return Object.assign({}, state, {
        videos: action.videos,
      });
    case TRAININGS.GET_TYPE_PROGRAM:
      return Object.assign({}, state, {
        program: action.program,
      });
    case TRAININGS.GET_EXPIRED_STATUS:
      return Object.assign({}, state, {
        isExpired: action.isExpired,
      });
    default:
      return state
  }
}