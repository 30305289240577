import React, {Component} from 'react';
import { Link } from 'react-router-dom';

import './styles/notFound.scss';

export default class NotFound extends Component {

    render() {
        return(
            <div className="notFound">
                <div className="notFound__main">
                    <p>Страница, которую вы&nbsp;искали не&nbsp;найдена или больше не&nbsp;существует.</p>
                    <Link to="/" className="link-text">Вернуться на&nbsp;главную страницу</Link>
                </div>
                <div className="notFound__image">
                    <img src={require('../../static/images/icons/error-404.svg')} alt="error-404"/>
                </div>
            </div>
        )
    }
}