import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ModalError from './ModalError';

import { closeModal, changeNameModal } from '../../actions/modal';

import './styles/modals.scss';

class Modals extends Component {

    componentDidMount() {
        // handler for close popup for ESC
        document.addEventListener('keydown', (event) => {
            if(event.keyCode === 27) {
                this.props.closeModal();
                this.props.changeNameModal(null);
            }
        });
    }

    // handler for close popup for ModalWindow
    handleCloseModal(event) {
        if (event.target.classList.contains('modalWindow')) {
            this.props.closeModal();
            this.props.changeNameModal(null);
        }
    }

    render() {

        const {
            isActive,
            nameModal,
            errorText,
            closeModal,
            changeNameModal,
        } = this.props;

        return (
            <div className={`modalWindow ${isActive ? '_active' : ''}`} onClick={ this.handleCloseModal.bind(this) }>
                <div className="modalWindow__wrapper">
                    <button className="modalWindow__close"
                        onClick={ () => {
                            this.props.closeModal();
                            this.props.changeNameModal(null);
                        }}
                    >
                        <img src={require('../../static/images/icons/icon-close-modal.svg')} alt=""/>
                    </button>
                    <div className="modalWindow__body">
                    {
                        nameModal === 'error' &&
                        <ModalError
                            changeNameModal={ changeNameModal }
                            closeModal={ closeModal }
                            errorText={errorText}
                        />
                    }
                    </div>
                </div>
            </div>
        )  
    }
}


function mapStateToProps(state) {
    return {
        isActive: state.modal.isActive,
        nameModal: state.modal.nameModal,
        errorText: state.modal.errorText
    }
}
  
const mapDispatchToProps = dispatch => bindActionCreators({
    closeModal,
    changeNameModal
}, dispatch);
  
export default connect(mapStateToProps, mapDispatchToProps)(Modals);