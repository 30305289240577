import React, {Component} from 'react';

import './styles/header.scss';

const mobileMaxWidth = 699;
// const tabletPortraitMax = 899;

export default class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bgStatus: false
    }
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll.bind(this));

    setTimeout(() => {
      document.querySelector('.header').classList.add('_show');
    }, 200)
  }

  handleScroll() {
    const heightHeaderMobile = 40;

    let posTop = window.pageYOffset || document.documentElement.scrollTop;

    if (window.innerWidth <= mobileMaxWidth && posTop > heightHeaderMobile){
      this.setState({
        bgStatus: true,
      });
    } else {
      this.setState({
        bgStatus: false,
      });
    }
  }

  render() {
    return(
      <div className={`header ${this.state.bgStatus && '_dark'}`}>
        <div className="container">
          <a href="/" className="header__logo">
            <img src={ require('../../static/images/logo.svg')} alt="logo" />
          </a>
          <div className="header__phone">
            <p>Что-то пошло не так? Звони по номеру </p>
            <a href="tel:+78633109566">+7 (863) 310-95-66</a>
          </div>
        </div>
      </div>
    )
  }
}
