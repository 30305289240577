import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers';

let middleware = [thunkMiddleware];

if (process.env.REACT_APP_NODE_ENV !== "production") {
  middleware.push(logger);
}

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middleware))
  )
  return store
};
