import React, {Component} from 'react';

import Header  from '../components/Header';
import NotFound from '../components/NotFound';

// error 404
export default class StartScreen extends Component {
  render(){
    return(
      <div className="NotFoundScreen">
        <Header />
        <NotFound />
      </div>
    )
  }
}