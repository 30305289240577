import React, { Component } from 'react';

import './styles/modalError.scss';

class ModalError extends Component {

    render() {

        const {
            errorText
        } = this.props;

        return (
            <div className="modal-error">
                <p>{errorText}</p>
            </div>
        )  
    }
}

export default ModalError;