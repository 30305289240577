import { combineReducers } from 'redux';

import processing from './processing';
import trainings from './trainings';
import modal from './modal';

export default combineReducers({
  processing,
  trainings,
  modal
});