import React, {Component} from 'react';

import Header  from '../components/Header';
import Training from '../components/Training';

export default class TrainingScreen extends Component {

  render(){
    return(
      <div className="trainingScreen">
        <Header />
        <Training />
      </div>
    )
  }
}
