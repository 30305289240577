import React, {Component} from 'react';

import Header  from '../components/Header';
import Finish from '../components/Finish';
import Modals from '../components/Modals';

export default class FinishScreen extends Component {

  componentDidMount() {
    setTimeout(() => {
      document.querySelector('.finishGuide').classList.add('_show');
    }, 200)
  }

  render(){
    return(
      <div className="finishScreen">
        <Header />
        <Finish />
        <Modals />
      </div>
    )
  }
}
