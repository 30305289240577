import React, {Component} from 'react';

import Header  from '../components/Header';
import Trainings from '../components/Trainings';

export default class TrainingsScreen extends Component {

  render(){
    return(
      <div className="trainingsScreen">
        <Header />
        <Trainings />
      </div>
    )
  }
}
