import axios from 'axios';

// ACTION
export const TRAININGS = {
  GET_LIST: 'GET_LIST',
  GET_TYPE_PROGRAM: 'GET_TYPE_PROGRAM',
  GET_EXPIRED_STATUS: 'GET_EXPIRED_STATUS',
};

// get list with videos
export function getVideosList(hash) {
  return async (dispatch) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/trainings/${hash}`,
      headers: {
        'Accept-type': 'application/json',
        'Content-type': 'application/json',
      }
    })
    .then(function (response) {
      dispatch({
        type: TRAININGS.GET_LIST,
        videos: response.data.data.is_expired ? [] : response.data.data.videos,
      })

      dispatch({
        type: TRAININGS.GET_TYPE_PROGRAM,
        program: response.data.data.type === 1 ? 'old' : 'new',
      })

      dispatch({
        type: TRAININGS.GET_EXPIRED_STATUS,
        isExpired: response.data.data.is_expired,
      })
    })
    .catch(function (error) {
      console.log(error.response);

      dispatch({
        type: TRAININGS.GET_LIST,
        videos: false,
      })

      dispatch({
        type: TRAININGS.GET_TYPE_PROGRAM,
        program: null,
      })

      dispatch({
        type: TRAININGS.GET_EXPIRED_STATUS,
        isExpired: null,
      })
    });
  }
}