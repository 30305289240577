import React, {Component} from 'react';
import { Link } from "react-router-dom";

import WithInfiniteMove from '../WithInfiniteMove';

import './styles/start.scss';

export default class Start extends Component {
  render() {
    return(
      <div className="startGuide">
        <div className="startGuide__decor">
          <div className="startGuide__decor__layer"></div>
          <div className="startGuide__decor__text">
            <WithInfiniteMove timesToClone={4} direction="left" duration={40}>
              <img
                src={require('../../static/images/startScreen/moving-words.png')}
                alt="Больше никаких отговорок"
              />
            </WithInfiniteMove>
          </div>
        </div>
        <div className="container">
          <div className="startGuide__text">
            <h1>Фитнес-навигатор</h1>
            <p>30&nbsp;секунд и&nbsp;твоя индивидуальная программа тренировок готова.</p>
            <Link
              className="btn btn__type-1"
              to="/processing"
            >
              <span>Начать сейчас</span>
            </Link>
          </div>
          <div className="startGuide__img">
            <img src={ require('../../static/images/startScreen/bg_img.png') } alt="" className="person" />
            <img src={ require('../../static/images/startScreen/circle-decor.svg') } alt="" className="circle" />
          </div>
          <p className="footnote" dangerouslySetInnerHTML={{__html: `FITRON &copy;&nbsp;2016-${new Date().getFullYear()}&nbsp;<a href="https://fitron.club/policy" target="_blank" rel="noopener noreferrer">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</a>`}}/>
        </div>
        <div className="startGuide__mobile">
          <div className="startGuide__mobile__img">
            <img src={ require('../../static/images/startScreen/bg_img.png') } alt="" className="person-mobile" />
            <img src={ require('../../static/images/startScreen/circle-decor.svg') } alt="" className="circle-mobile" />
          </div>
          <Link
            className="btn btn__type-1 mobile-btn"
            to="/processing"
          >
            <span>Начать сейчас</span>
          </Link>
          <p className="footnote-mobile">FITRON &copy;&nbsp;2016-2019&nbsp;<a href="https://fitron.club/policy" target="_blank" rel="noopener noreferrer">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</a></p>
        </div>
      </div>
    )
  }
}