// ACTION
export const PROCESSING = {
  CHANGE_STEP: 'CHANGE_STEP',
  SET_CONTACTS: 'SET_CONTACTS',
  SET_STEP_1: 'SET_STEP_1',
  SET_STEP_2: 'SET_STEP_2',
  SET_STEP_3: 'SET_STEP_3',
  SET_STEP_4: 'SET_STEP_4'
};

// ACTION CREATORS
export function changeStep(step) {
  return {
    type: PROCESSING.CHANGE_STEP,
    step
  }
}

export function setStartInfo(gender, age, height, weight) {
  return {
    type: PROCESSING.SET_STEP_1,
    gender,
    age,
    height,
    weight
  }
}

export function setGoal(id, value) {
  return {
    type: PROCESSING.SET_STEP_2,
    id,
    value
  }
}

export function setPlace(id, value) {
  return {
    type: PROCESSING.SET_STEP_3,
    id,
    value
  }
}

export function setCount(id, value) {
  return {
    type: PROCESSING.SET_STEP_4,
    id,
    value
  }
}
